import {store} from 'redux/store';

const multiTabLink = ({onClick, onContextMenu, url, target = '_top', title, className, style, type}) => {
  const {
    userReducer: {dashboard},
  } = store.getState();
  const urlObj = new URL(url, window.location.origin);
  let hrefUrl = url;

  // Check if the param exists; if not, add it
  if (url && !urlObj.searchParams.has('dashboard_name')) {
    const searchParamSymbol = url?.split('')?.includes('?') ? '&' : '?';
    hrefUrl = `${url}${searchParamSymbol}dashboard_name=${dashboard}`;
  }

  switch (type) {
    case 'button':
      return (
        <a
          href={hrefUrl}
          target="_top"
          onClick={onClick}
          onContextMenu={onContextMenu}
          className={`text-[#000000] py-1 px-2 border-solid border-1 border-[#cacccf] rounded-sm hover:border-[#40a9ff] ${className}`}
          style={style}
        >
          {title || ''}
        </a>
      );
    default:
      return (
        <a
          href={hrefUrl}
          target={target}
          onClick={onClick}
          onContextMenu={onContextMenu}
          className={className}
          style={style}
        >
          {title || ''}
        </a>
      );
  }
};

export default multiTabLink;
