import {Menu} from 'antd';
import moment from 'moment';
import {withRouter} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import '@fontsource/montserrat';

import {store} from 'redux/store';
import {useMenuContext} from 'context/menuContext';
import {useCalenderContext} from 'context/calenderContext';
import {useDayCalenderContext} from 'context/dayCalenderContext';
import {useLocalStorage} from 'context/onboardStepsContext';

import actions from 'redux/user/actionTypes';
import everestFleetLogo from '../../../assets/images/everestFleetLogo.png';
import createSidebarMenu from './sidebarUtils';
import {Dashboard} from 'utility/dashboardEnum';
import {useLocation} from 'react-router-dom/cjs/react-router-dom.min';
import MultiTabLink from 'components/common/multiTabLink';

const Sidebar = ({location: {pathname}}) => {
  const {
    userReducer: {name, manager, cityName, location, teamName, username, dashboard, permissions},
  } = store.getState();
  const {setMenu, routeHistory, setRouteHistory, previousRoute, setPreviousRoute} = useMenuContext();
  const {calenderDate, setCalenderDate, onChangeWeekDate} = useCalenderContext();
  const {dayCalenderDate} = useDayCalenderContext();
  const {setDataInLocalStorage} = useLocalStorage();
  const dispatch = useDispatch();

  const [sideBarMenu, setSideBarMenu] = useState([]);

  const locationPath = useLocation(); // Works for both v5 and v6

  useEffect(() => {
    let newHistory = routeHistory;
    setRouteHistory(prevHistory => {
      // Add the current location to the history
      newHistory = [...prevHistory, locationPath.pathname];

      // If we have more than two items, remove the oldest one
      if (newHistory.length > 2) {
        newHistory.shift();
      }

      return newHistory;
    });
    if (!newHistory.includes(previousRoute?.path)) {
      setPreviousRoute({});
    }
  }, [locationPath]);

  useEffect(() => {
    if (locationPath.pathname != routeHistory[routeHistory.length - 1]) {
      return;
    }

    const path = [];
    sideBarMenu.forEach(row => {
      if (row.children) {
        row.children.forEach(innerRow => {
          if (innerRow.key === pathname) {
            path.push(innerRow);
          }
        });
      } else if (row.key === pathname) {
        path.push(row);
      }
    });
    // Appcues: Identify users
    if (path.length) {
      window.Appcues.identify(username?.toString(), {
        name,
        manager: manager?.[0],
        teamName: teamName?.[0],
        cityName: cityName?.[0],
        location: location?.[0],
        dashboard,
        page: path[0].label,
        created_at: moment().format('YYYY-MM-DD, h:mm:ss a'),
      });
    }
    if (path.length) {
      setMenu(path[0].label);
      const calenderType = ['THIS_WEEK'].includes(path[0].calendar_type);
      const hisaabCalenderType = ['LAST_WEEK'].includes(path[0].calendar_type);
      const startWeek = new Date(moment().startOf('isoweek').format('YYYY-MM-DD'));
      const dayDate = new Date(dayCalenderDate.day);
      if (onChangeWeekDate === false) {
        if (dayDate >= startWeek) {
          if (calenderType === true) {
            const startOfWeek = moment().startOf('isoweek').format('YYYY-MM-DD');
            const endOfWeek = moment().endOf('isoweek').format('YYYY-MM-DD');
            setCalenderDate({startOfWeek, endOfWeek});
          } else {
            const startOfWeek = moment().startOf('isoweek').subtract(1, 'week').format('YYYY-MM-DD');
            const endOfWeek = moment().endOf('isoweek').subtract(1, 'week').format('YYYY-MM-DD');
            setCalenderDate({startOfWeek, endOfWeek});
          }
        }
        if (dayDate < startWeek) {
          if (path[0].uri === 'phonepe-eip') {
            const startOfWeek = moment().startOf('isoweek').format('YYYY-MM-DD');
            const endOfWeek = moment().endOf('isoweek').format('YYYY-MM-DD');
            setCalenderDate({startOfWeek, endOfWeek});
          } else if (calenderType === true) {
            const startOfWeek = moment().startOf('isoweek').subtract(1, 'week').format('YYYY-MM-DD');
            const endOfWeek = moment().endOf('isoweek').subtract(1, 'week').format('YYYY-MM-DD');
            setCalenderDate({startOfWeek, endOfWeek});
          } else {
            const startOfWeek = moment().startOf('isoweek').subtract(2, 'week').format('YYYY-MM-DD');
            const endOfWeek = moment().endOf('isoweek').subtract(2, 'week').format('YYYY-MM-DD');
            setCalenderDate({startOfWeek, endOfWeek});
          }
        }
      }
      if (hisaabCalenderType) {
        if (calenderDate.startOfWeek === moment().startOf('isoweek').format('YYYY-MM-DD')) {
          setCalenderDate({
            startOfWeek: moment().startOf('isoweek').subtract(1, 'weeks').format('YYYY-MM-DD'),
            endOfWeek: moment().endOf('isoweek').subtract(1, 'weeks').format('YYYY-MM-DD'),
          });
        } else {
          setCalenderDate({
            startOfWeek: calenderDate.startOfWeek,
            endOfWeek: calenderDate.endOfWeek,
          });
        }
      }
    }
  }, [sideBarMenu, pathname]);

  useEffect(() => {
    const sideBarMenuTemp = createSidebarMenu(permissions, dashboard);
    setSideBarMenu(sideBarMenuTemp);
  }, [permissions, dashboard]);

  const defaultOpenKey = sideBarMenu.reduce((acc, {key, children}) => {
    if (children) {
      children.forEach(child => {
        if (child.key === pathname) {
          acc.push(key);
        }
      });
    }
    return acc;
  }, []);

  return (
    <>
      <div className="flex flex-col items-center border-b-[1px]">
        <img src={everestFleetLogo} alt="app-logo" className="h-16 my-2" />
      </div>
      {dashboard === Dashboard.ONBOARDDRIVER ? (
        <>
          <div className="flex flex-col items-center my-3">
            <MultiTabLink
              type="button"
              className="rounded-1 font-semibold text-center py-1.5"
              style={{backgroundColor: '#0b7677', color: '#fff', width: '150px'}}
              title="Add Driver"
              onClick={() => {
                dispatch({type: actions.ONBOARDING_STATUS, payload: ''});
                dispatch({type: actions.ONBOARDING, payload: {}});
                setDataInLocalStorage({index: 0});
              }}
              url={`/#/home/onboardDriver`}
            />
          </div>
          <div className="flex flex-col items-center my-3">
            <MultiTabLink
              type="button"
              className="rounded-1 font-bold text-center py-1.5"
              style={{borderColor: '#0b7677', color: '#0b7677', width: '150px'}}
              title="Check Driver"
              url={`/#/home/checkDriver`}
            />
          </div>
        </>
      ) : (
        ''
      )}
      <Menu selectedKeys={[pathname]} defaultOpenKeys={defaultOpenKey} mode="inline" items={sideBarMenu} />
    </>
  );
};

export default withRouter(Sidebar);
