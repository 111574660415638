import actions from './actionTypes';

export const initialState = {
  taSearchList: null,
  complexId: null,
  team: null,
  teamName: null,
  city: null,
  cityName: null,
  loc: null,
  location: null,
  userRole: null,
  userData: null,
  teamId: null,
};

// eslint-disable-next-line default-param-last
const garageReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case actions.SET_TA_LIST_DATA:
      return {
        ...state,
        taSearchList: payload,
      };
    case actions.SET_CITY_WISE_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default garageReducer;
