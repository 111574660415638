import {lazy, Suspense, useEffect, useState} from 'react';
import {ConnectedRouter} from 'connected-react-router';
import {Route, Redirect, Switch} from 'react-router-dom';
import {CSSTransition, SwitchTransition} from 'react-transition-group';
import {connect} from 'react-redux';

import {history} from 'redux/store';

import createRoutes from 'routerUtils';

import AuthLayout from './layout';
import {Spin} from 'antd';

const authRoutes = [
  {
    path: '/auth/sign-in',
    key: 'SIGN-IN',
    exact: true,
    Component: lazy(() => import('pages/auth/sign-in')),
  },
  {
    path: '/auth/page-not-found',
    key: 'PAGE-NOT-FOUND',
    exact: true,
    Component: lazy(() => import('pages/auth/page-not-found')),
  },
  {
    path: '/auth/unauthorized',
    key: 'UNAUTHORIZED',
    exact: true,
    Component: lazy(() => import('pages/auth/unauthorized')),
  },
  {
    path: '/visitors',
    key: 'VISITORS',
    exact: true,
    Component: lazy(() => import('pages/visitors')),
  },
  {
    path: '/verifyOtp',
    key: 'VERIFYOTP',
    exact: true,
    Component: lazy(() => import('pages/verifyOtp')),
  },
  {
    path: '/thankYouPage',
    key: 'THANKYOUPAGE',
    exact: true,
    Component: lazy(() => import('pages/thankYouPage')),
  },
];

const extraRoutes = [
  {
    path: '/home/driverHisaabData',
    key: 'DRIVERHISAABDATA',
    exact: true,
    Component: lazy(() => import('pages/driverHisaabData')),
  },
  {
    path: '/home/driverLeasHisaab/:driverId/:startDate/:endDate',
    key: 'DRIVER_LEASING_HISAAB',
    exact: true,
    Component: lazy(() => import('pages/driverLeasHisaab')),
  },
  {
    path: '/home/deadKMsReportData',
    key: 'DEADKMSREPORTDATA',
    exact: true,
    Component: lazy(() => import('pages/deadKMsReportData')),
  },
  {
    path: '/home/dashboard/carDetailsAdd',
    key: 'CARDETAILSADD',
    exact: true,
    Component: lazy(() => import('pages/carDetailsAdd')),
  },
  {
    path: '/home/dashboard/carDetailsEdit/:id',
    key: 'CARDETAILSEDIT',
    exact: true,
    Component: lazy(() => import('pages/carDetailsEdit')),
  },
  {
    path: '/home/audit/visibilityDash/carStatus',
    key: 'carStatus',
    exact: true,
    Component: lazy(() => import('pages/audit/visibilityDash/carStatus')),
  },
  {
    path: '/home/dashboard/driverTestAction/:id',
    key: 'driverTestAction',
    exact: true,
    Component: lazy(() => import('pages/driverTestAction')),
  },
  {
    path: '/home/onboardDriver/securityDeposit/details/:id/:driverId',
    key: 'securityDepositDetails',
    exact: true,
    Component: lazy(() => import('pages/securityDepositDetails')),
  },
  {
    path: '/home/onboardDriver/documentationCharge/details/:id',
    key: 'documentationChargeDetails',
    exact: true,
    Component: lazy(() => import('pages/documentationChargeDetails')),
  },
  {
    path: '/home/dashboard/driverTrainingAction/:id',
    key: 'driverTrainingAction',
    exact: true,
    Component: lazy(() => import('pages/driverTrainingAction')),
  },
  {
    path: '/home/onboardDriver/onboardDocumentCharges/add',
    key: 'addDocumentCharges',
    exact: true,
    Component: lazy(() => import('pages/addDocumentCharges')),
  },
  {
    path: '/home/onboardDriver/onboardDocumentCharges/:id',
    key: 'documentCharges',
    exact: true,
    Component: lazy(() => import('pages/onboardDocumentChargeDetails')),
  },
  {
    path: '/home/onboardDriver/deposit/add',
    key: 'securityDeposit',
    exact: true,
    Component: lazy(() => import('pages/addOnboardSecurityDeposit')),
  },
  {
    path: '/home/onboardDriver/deposit/:id',
    key: 'securityDeposit',
    exact: true,
    Component: lazy(() => import('pages/onboardSecurityDeposit')),
  },
  {
    path: '/home/onboardDriver',
    key: 'onboardDriver',
    exact: true,
    Component: lazy(() => import('pages/onboardDriver')),
  },
  {
    path: '/home/checkDriver',
    key: 'checkDriver',
    exact: true,
    Component: lazy(() => import('pages/checkDriver')),
  },
  {
    path: '/home/dashboard/contractUpdate/:id',
    key: 'contractUpdate',
    exact: true,
    Component: lazy(() => import('pages/contractUpdate')),
  },
  {
    path: '/home/dashboard/kuberUpdate/:id',
    key: 'kuberUpdate',
    exact: true,
    Component: lazy(() => import('pages/kuberUpdate')),
  },
  {
    path: '/home/onboardDriver/securityDeposit/deposit',
    key: 'securityDepositAdd',
    exact: true,
    Component: lazy(() => import('pages/securityDepositAdd')),
  },
  {
    path: '/home/onboardDriver/drivers/profile/:id',
    key: 'drivers',
    exact: true,
    Component: lazy(() => import('pages/drivers/profile')),
  },
  {
    path: '/home/onboardDriver/documentationCharge/charge',
    key: 'newDocumentationCharge',
    exact: true,
    Component: lazy(() => import('pages/documentationChargeAdd')),
  },
  {
    path: '/home/auditTeam/add',
    key: 'addteam',
    exact: true,
    Component: lazy(() => import('pages/addteam')),
  },
  {
    path: '/home/auditTeam/:cityId/edit/:teamId/:type',
    key: 'editTeam',
    exact: true,
    Component: lazy(() => import('pages/editTeam')),
  },
  {
    path: '/home/dmTeam/add',
    key: 'dmTeam',
    exact: true,
    Component: lazy(() => import('pages/addDmTeam')),
  },
  {
    path: '/home/dmTeam/:cityId/edit/:teamId/:type',
    key: 'dmEditTeam',
    exact: true,
    Component: lazy(() => import('pages/dmEditTeam')),
  },
  {
    path: '/home/onboardDriver/referralCampaign/add',
    key: 'addReferralCampaign',
    exact: true,
    label: 'Create Referral Campaign',
    Component: lazy(() => import('pages/addReferralCampaign')),
  },
  {
    path: '/home/onboardDriver/referralCampaign/edit/:id',
    key: 'editReferralCampaign',
    exact: true,
    label: 'Referral Campaign Details',
    Component: lazy(() => import('pages/editReferralCampaign')),
  },
  {
    path: '/home/onboardDriver/referralCampaign/edit/:id',
    key: 'editReferralCampaign',
    exact: true,
    Component: lazy(() => import('pages/editReferralCampaign')),
  },
  {
    path: '/home/hisaabSummaryTable',
    key: 'hisaabSummaryTable',
    exact: true,
    Component: lazy(() => import('pages/hisaabSummaryTable')),
  },
  {
    path: '/home/onboardDriver/referralsList/details',
    key: 'referralDetails',
    exact: true,
    label: 'Referral Details',
    Component: lazy(() => import('pages/referralDetails')),
  },
  {
    path: '/home/onboardDriver/followUp/callHistoryTable',
    key: 'callHistoryTable',
    exact: true,
    label: 'Followup Details',
    Component: lazy(() => import('pages/callHistoryTable')),
  },
  {
    path: '/home/garage/garageRepairAssign/:id',
    key: 'garageRepairAssignBay',
    exact: true,
    Component: lazy(() => import('pages/garage/garageRepairAssignBay')),
  },
  {
    path: '/home/garage/:auditType/trialAuditForm/:jobCardId/:trialId',
    key: 'trialAuditForm',
    exact: true,
    Component: lazy(() => import('pages/garage/trialAuditForm')),
  },
  {
    path: '/home/garage/technicianJobs/requestSpareParts/:id',
    key: 'technicianRequestSpareParts',
    exact: true,
    Component: lazy(() => import('pages/garage/technicianRequestSpareParts')),
  },
  {
    path: '/home/garage/technicianJobsStatus/:jobCardId',
    key: 'technicianJobsStatus',
    exact: true,
    Component: lazy(() => import('pages/garage/technicianJobsStatus')),
  },
  {
    path: '/home/garage/sparePartRequestApproval/:spareReqId/:jobCardId',
    key: 'sparePartRequestApproval',
    exact: true,
    Component: lazy(() => import('pages/garage/sparePartRequestApproval')),
  },
  {
    path: '/home/garage/sparePartsIssueParts/:jobCardId',
    key: 'sparePartsIssuePartsRequest',
    exact: true,
    Component: lazy(() => import('pages/garage/sparePartsRequest')),
  },
  {
    path: '/home/garage/repairJobsView/:jobCardId',
    key: 'jobCard',
    exact: true,
    Component: lazy(() => import('pages/garage/jobCard')),
  },
  {
    path: '/home/garage/garageRepairAssign/addJob/:id',
    key: 'garageRepairAddJob',
    exact: true,
    Component: lazy(() => import('pages/garage/garageRepairAssign/addJob')),
  },
];

const Router = ({authorized, permissions}) => {
  const [routes, setRoutes] = useState(authRoutes);

  useEffect(() => {
    if (authorized) {
      let routesTemp = [...extraRoutes];

      // Creating and pushing permissions routes of all dashboards in routes
      Object.values(permissions || {}).forEach(permission => {
        routesTemp = [...routesTemp, ...createRoutes(permission)];
      });
      setRoutes(prev => [...prev, ...routesTemp]);
    }
  }, [authorized, permissions]);

  return (
    <ConnectedRouter history={history}>
      <AuthLayout>
        <Route
          render={({location}) => (
            <SwitchTransition>
              <CSSTransition key={location.pathname} appear timeout={300} classNames="fade-in">
                <Switch location={location}>
                  <Route exact path="/" render={() => <Redirect to="/home/dashboard" />} />
                  {routes.map(({path, Component, label, exact, key}) => {
                    return (
                      <Route
                        path={path}
                        key={key}
                        exact={exact}
                        render={() => (
                          <Suspense
                            fallback={
                              <div className="h-screen flex justify-center items-center">
                                <Spin spinning />
                              </div>
                            }
                          >
                            <Component pageName={label} />
                          </Suspense>
                        )}
                      />
                    );
                  })}
                  {/* <Redirect to="/auth/page-not-found" /> */}
                </Switch>
              </CSSTransition>
            </SwitchTransition>
          )}
        />
      </AuthLayout>
    </ConnectedRouter>
  );
};

const mapStateToProps = ({userReducer}) => ({
  authorized: userReducer.authorized,
  permissions: userReducer.permissions,
});

export default connect(mapStateToProps, null)(Router);
