/* eslint-disable react/jsx-no-constructed-context-values */
import {getQueryParams} from 'components/common/onboardTable/urlParams';
import {createContext, useState, useContext} from 'react';

// initial default value
const MenuContext = createContext({
  menu: '',
  pageObject: {
    label: 'Dashboard',
    key: '/home/dashboard',
    icon: '',
    uri: 'dashboard',
    calendar_type: '"THIS_WEEK"',
  },
  previousRoute: {
    path: '',
    currentPage: 1,
    pageSize: 10,
    filters: {pagination: {page: 1, size: 10}},
  },
  routeHistory: [],
  filters: {page: 1, page_size: 10},
});

const MenuProvider = ({children}) => {
  // condition that sends params if present in url else sends default values
  const queryParamsCondition = urlParams => (Object.keys(urlParams).length > 0 ? urlParams : {page: 1, page_size: 10});

  // Get the current URL's search parameters
  const params = getQueryParams(location);

  const [menu, setMenu] = useState('');
  const [routeHistory, setRouteHistory] = useState([]);
  const [previousRoute, setPreviousRoute] = useState({});
  const [filters, setFilters] = useState(queryParamsCondition(params));
  const [pageObject, setPageObject] = useState({
    label: 'Dashboard',
    key: '/home/dashboard',
    icon: '',
    uri: 'dashboard',
    calendar_type: '"THIS_WEEK"',
  });

  return (
    <MenuContext.Provider
      value={{
        menu,
        setMenu,
        routeHistory,
        setRouteHistory,
        previousRoute,
        setPreviousRoute,
        pageObject,
        setPageObject,
        filters,
        setFilters,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const useMenuContext = () => useContext(MenuContext);
export default MenuProvider;
