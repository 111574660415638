import {call, put, all, takeEvery} from 'redux-saga/effects';
import {notification} from 'antd';

import {login, currentAccountLoad, logout} from 'services/authorization';
import actions from './actionTypes';

function* LOGIN(userAction) {
  const {payload} = userAction;

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });

  const success = yield call(login, payload);

  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    });

    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
      placement: 'top',
    });
  }

  if (!success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
  }
}

function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });

  const success = yield call(currentAccountLoad);

  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        authorized: true,
      },
    });
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  });
}

function* LOGOUT({payload}) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });

  const success = yield call(() => logout(payload));

  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        authorized: false,
      },
    });
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  });
}

function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(),
  ]);
}

export default rootSaga;
